import React from "react"
import Heading from "../../shared/Heading"
import "./Hero.scss"

export default function Hero({ text, header }) {
  return (
    <div
      className="hero-bg"
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <div className="container position-relative">
        <div className="row pb-4">
          <div className="col-lg-6 col-sm-12 col-xs-12  hero-text-column-blog">
            <Heading variant="58" className="header text-capitalize">
              {header}
            </Heading>
            <div className="hero-text-blog">{text}</div>
          </div>
          <div className="col-lg-6  col-sm-12  col-xs-12 ">
            <div className="hero-img-blog position-relative">
              <img
                src="/blog/hero.svg"
                alt="blog-hero"
                className="img-fluid display-tablet-none"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
