import React from "react"
import "./Blog.scss"
import Card from "../shared/BlogCard"
import cardData from "../shared/Data/blogs"
import { Link } from "gatsby"

function Content() {
  const latestBlog = cardData[cardData.length - 1]
  return (
    <div className="container">
      <div className="blog-display">
        <div className="first-blog-wrapper">
          <Link
            to="/blog/what-is-a-great-product-development-strategy"
            style={{ textDecoration: "none" }}
          >
            <div className="row justify-content-center">
              <div className="col-md-6">
                <img
                  src={latestBlog.img}
                  alt="blog-1"
                  className="header-blog-img"
                  loading="lazy"
                />
              </div>
              <div className="col-md-6">
                <p className="date">{latestBlog.date}</p>
                <p className="header">{latestBlog.header}</p>
                <p className="text">{latestBlog.text}</p>
              </div>
            </div>
          </Link>
        </div>

        <div className="card-wrapper">
          <Card cardData={cardData} col={4} gap={4} skipFirst />
        </div>
      </div>
    </div>
  )
}

export default Content
