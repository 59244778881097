import React from "react"
import "./Card.scss"
import { substring } from "../../utils/helpers"
import { Link } from "gatsby"
function Card({ cardData, col, gap, skipFirst = false }) {
  function formatString(str) {
    str = str.toLowerCase()
    str = str.replace(/\?/g, "")
    str = str.replace(/\s+/g, "-")
    if (str.endsWith("?")) {
      return str.slice(0, -1)
    } else {
      return str
    }
  }

  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    const updateImagePath = () => {
      const isMobile = window.innerWidth <= 768 // You can set the breakpoint for mobile view
      setIsMobile(isMobile)
    }

    // Set image path on load
    updateImagePath()

    // Update image path on window resize
    window.addEventListener("resize", updateImagePath)

    return () => {
      window.removeEventListener("resize", updateImagePath)
    }
  }, [cardData])
  return (
    <div className="blog-card">
      <div
        className={`row ${gap > 0 ? "gx-" + gap : ""}  justify-content-start`}
      >
        {cardData
          .slice(0)
          .reverse()
          .map((card, i) => {
            const link = card.updatedLink
              ? `/blog/${card.updatedLink}`
              : `/blog/${formatString(card.header)}`
            // Ignore first latest blog
            if (i === 0 && skipFirst) return null
            return (
              <div className={`col-md-${col} mt-md-2 mt-sm-4  mt-4 `} key={i}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <div className="blog-single-card">
                    <div className="img-wrapper text-center">
                      <img
                        src={isMobile ? card.imgMob : card.img}
                        alt="img"
                        className="img-wrapper-class"
                        loading="lazy"
                      />
                    </div>
                    <p className="date-card">{card.date}</p>
                    <p className="header-card">{card.header}</p>
                    <p className="text-card">{substring(card.text, 170)}</p>
                  </div>
                </Link>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Card
